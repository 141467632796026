import { graphql, StaticQuery } from 'gatsby';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import PageLoadingData from './../Common/PageLoadingData';

const NomineesPastWacky = ({ pastNomineesTitle = 'Past nominees' }) => {
     const [isDragging, setIsDragging] = useState(false);
     const [startPos, setStartPos] = useState(0);
     const [currentTranslate, setCurrentTranslate] = useState(0);
     const [prevTranslate, setPrevTranslate] = useState(0);
     const [isClick, setIsClick] = useState(true);
     const [opacity, setOpacity] = useState(0);
     const today = new Date();
     const currYear = today.getFullYear();

     function handleTouchStart(event) {
          setIsDragging(true);
          setStartPos(getPositionX(event));
     }

     function getPositionX(event) {
          return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX;
     }

     const handleTouchMove = (event) => {
          if (isDragging) {
               const currentPosition = getPositionX(event);
               const newCurrentTranslate = prevTranslate + currentPosition - startPos;
               setCurrentTranslate(newCurrentTranslate);
               setIsClick(false);
          }
     };
     const pathNameWacky = typeof window !== 'undefined' ? window.location.pathname : '';
     const currentURL = pathNameWacky.slice(15, pathNameWacky.length);
     const handleTouchEnd = () => {
          setIsDragging(false);
          setPrevTranslate(currentTranslate);
          setTimeout(() => {
               setIsClick(true);
          }, 0);

          if (typeof document !== 'undefined') {
               const offsetLeftUl = document.querySelector('.past-nominees-year-slide').offsetLeft;
               const widthSlide = document.querySelector('.past-nominees-year-slide').offsetWidth;
               const widthYearList = document.querySelector('.past-nominees-year-list').offsetWidth;
               const setOffsetRight = widthSlide - widthYearList;

               if (offsetLeftUl > 0 && isDragging && currentTranslate !== prevTranslate) {
                    setCurrentTranslate(setOffsetRight);
                    setPrevTranslate(setOffsetRight);
                    document.querySelector('.past-nominees-year-list').classList.add('remove-after');
               } else {
                    document.querySelector('.past-nominees-year-list').classList.remove('remove-after');
               }

               if (currentTranslate < 0) {
                    setCurrentTranslate(0);
                    setPrevTranslate(0);
               }
          }
     };

     useEffect(() => {
          const showYearActive = () => {
               if (typeof document !== 'undefined') {
                    const yearListWidth = document.querySelector('.past-nominees-year-list')?.offsetWidth;
                    const yearListWidthUl = document.querySelector('.past-nominees-year-slide')?.offsetWidth;
                    const yearHidenWidth = yearListWidthUl - yearListWidth;
                    const WITH_BUTTON = yearListWidth / 3;

                    const activeYear = document.querySelector('.active-year');

                    const positionLeftActiveYear = activeYear?.offsetLeft;
                    const rightPositionUl = WITH_BUTTON;
                    const setRight = yearHidenWidth - positionLeftActiveYear + rightPositionUl;

                    if (positionLeftActiveYear <= yearHidenWidth + WITH_BUTTON) {
                         setCurrentTranslate(setRight);
                         setPrevTranslate(setRight);
                    }
                    setOpacity(1);
               }
          };
          showYearActive();
          if (typeof window !== 'undefined') {
               window.addEventListener('resize', () => {
                    showYearActive();
               });
          }
     }, []);

     return (
          <StaticQuery
               query={graphql`
                    query PastNomineesAllWacky {
                         allContentfulWackyPetCategory(sort: { order: ASC, fields: slug }) {
                              nodes {
                                   slug
                              }
                         }
                    }
               `}
               render={(data) => {
                    const nominees = get(data, 'allContentfulWackyPetCategory.nodes');
                    const nominessDog = nominees.filter((item) => item.slug.toLowerCase().includes('dog'));
                    const nominessCat = nominees.filter((item) => item.slug.toLowerCase().includes('cat'));
                    const nominessExotic = nominees.filter((item) => item.slug.toLowerCase().includes('exotic'));

                    return (
                         <section className="past-nominees past-nominees-wacky">
                              <h3>{pastNomineesTitle}</h3>
                              <div className={`past-nominees-year-list past-nominees-year-list-wacky ${opacity === 0 ? ' loading-data-nominees' : ''}  `}>
                                   {opacity === 0 ? (
                                        <PageLoadingData />
                                   ) : (
                                        <ul
                                             className="past-nominees-year-slide past-nominees-year-slide-wacky"
                                             onMouseDown={handleTouchStart}
                                             onMouseUp={handleTouchEnd}
                                             onMouseMove={handleTouchMove}
                                             onMouseLeave={handleTouchEnd}
                                             onTouchStart={handleTouchStart}
                                             onTouchEnd={handleTouchEnd}
                                             onTouchMove={handleTouchMove}
                                             style={{
                                                  right: `${-currentTranslate}px`,
                                                  opacity
                                             }}
                                        >
                                             {currentURL.includes('dog') && (
                                                  <>
                                                       {nominessDog?.map((nomi, index) => {
                                                            return (
                                                                 <li
                                                                      key={index}
                                                                      className={`${Number(nomi.slug.slice(0, 4)) === Number(currentURL.slice(0, 4)) ? 'active-year' : ''}`}
                                                                      onClick={() => {
                                                                           if (isClick) {
                                                                                handleClickYear(nomi.slug);
                                                                           }
                                                                      }}
                                                                 >
                                                                      <a href={'/wackypetnames/' + nomi.slug}>{nomi.slug.slice(0, 4)}</a>
                                                                 </li>
                                                            );
                                                       })}
                                                  </>
                                             )}
                                             {currentURL.includes('cat') && (
                                                  <>
                                                       {nominessCat?.map((nomi, index) => {
                                                            return (
                                                                 <li
                                                                      key={index}
                                                                      className={`${Number(nomi.slug.slice(0, 4)) === Number(currentURL.slice(0, 4)) ? 'active-year' : ''}`}
                                                                      onClick={() => {
                                                                           if (isClick) {
                                                                                handleClickYear(nomi.slug);
                                                                           }
                                                                      }}
                                                                 >
                                                                      <a href={'/wackypetnames/' + nomi.slug}>{nomi.slug.slice(0, 4)}</a>
                                                                 </li>
                                                            );
                                                       })}
                                                  </>
                                             )}
                                             {currentURL.includes('exotic') && (
                                                  <>
                                                       {nominessExotic?.map((nomi, index) => {
                                                            return (
                                                                 <li
                                                                      key={index}
                                                                      className={`${Number(nomi.slug.slice(0, 4)) === Number(currentURL.slice(0, 4)) ? 'active-year' : ''}`}
                                                                      onClick={() => {
                                                                           if (isClick) {
                                                                                handleClickYear(nomi.slug);
                                                                           }
                                                                      }}
                                                                 >
                                                                      <a href={'/wackypetnames/' + nomi.slug}>{nomi.slug.slice(0, 4)}</a>
                                                                 </li>
                                                            );
                                                       })}
                                                  </>
                                             )}
                                        </ul>
                                   )}
                              </div>
                         </section>
                    );
               }}
          />
     );
};

export default NomineesPastWacky;
