import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../Header/index';
import Footer from '../../footer';
import Layout from '../../layout';
import Blocks from '../../blocks';
import Seo from '../../seo';
import { getPreviewPage } from '../../../state/app';
import { RichText } from '../../richTextOptions';
import { WackyCard } from '../../BlockLibrary/Wacky/WackyCard';
import NomineesPastWacky from '../../BlockLibrary/Wacky/NomineesWacky';
import { titleCase } from '../../utilities';

class WackyCategoryPreview extends Component {
     constructor(props) {
          super(props);
     }

     render() {
          const { category, allStories, isLoading } = this.props;
          var tabInd = 0;
          // if (category.fields.banners) {
          //      var bannerBackground = category.fields.banners.fields.file.url;
          // }
          // var categorySlug = '/wackypetnames/' + category.fields.slug + '/';

          const allStoriesFilter = allStories.filter((story) => story.fields.petName);

          const getDefaultSeo = () => {
               const year = category.fields.slug.split('-')[0];
               let petType = category.fields.slug.split('-')[1];

               if (petType === 'exotic') {
                    petType = 'exotic pet';
               }

               const titleCasePetType = titleCase(petType);

               let defaultSeoTitle = `${year} Wacky ${titleCasePetType} Names | Top Unique & Funny ${titleCasePetType} Names of ${year}`;
               let defaultSeoMetaDescription = `Unleash the creativity with Nationwide's list of unique & funny ${petType} names from ${year}! View wacky pet name nominees from ${year} & find out who came in first.`;

               return { defaultSeoTitle, defaultSeoMetaDescription };
          }

          const { defaultSeoTitle, defaultSeoMetaDescription } = getDefaultSeo();

          return (
               <>
                    <Layout>
                         <Seo
                              title={category.fields.seoTitleTag || defaultSeoTitle}
                              canonical={category.fields.seoCanonicalUrl}
                              description={category.fields.seoMetaDescription || defaultSeoMetaDescription}
                              robots={category.fields.robotsDirective}
                              image={category.fields.socialImage ? category.fields.socialImage : category.fields.featuredImage}
                         />
                         {isLoading && <div className="page-loading">Loading ...</div>}
                         <Header header={category.fields.header} tabInd={(tabInd = tabInd + 1)}></Header>
                         <section className="wacky-category-infomation">
                              <div className="container">
                                   <div className="content">
                                        <h1>{category.fields.h1 ? category.fields.h1 : category.fields.name}</h1>
                                        {category.fields.top50Description && <RichText data={category.fields.top50Description}></RichText>}
                                   </div>
                              </div>
                         </section>
                         <section className="wacky-best-top">
                              <div className="container">
                                   <WackyCard allStories={allStoriesFilter} />
                              </div>
                         </section>
                         <section className='wacky-past-year'>
                              <NomineesPastWacky />
                         </section>
                         {typeof category.fields.rows != 'undefined' && <Blocks blocks={category.fields.rows} tabInd={tabInd}></Blocks>}
                         <Footer footer={category.fields.footer} tabInd={(tabInd = tabInd + 1)}></Footer>
                    </Layout>
               </>
          );
     }
}
WackyCategoryPreview.propTypes = {
     category: PropTypes.object
};

function mapStateToProps(state) {
     return {
          category: getPreviewPage(state)
     };
}

export default connect(mapStateToProps)(WackyCategoryPreview);
