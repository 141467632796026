import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../../state/app';
import { scrollToTopOffset } from '../ContentfulBlockArticles';
import { WackyArticleCard } from './WackyArticleCard';

const WackyCardBlock = ({ allStories, isPreview, storiesPerPage = 6 }) => {
     // const [recentStories, setRecentStories] = useState(storiesPerPage);
     // const handleClickShowMore = () => {
     //      setRecentStories(recentStories + storiesPerPage);
     // };

     // const handleClickShowLess = () => {
     //      setRecentStories(storiesPerPage);
     //      scrollToTopOffset();
     // };
     // const recentStoriesToShow = allStories?.slice(0, recentStories);

     // return (
     //      <>
     //           {recentStories && (
     //                <div className="list-best">
     //                     <div className="row">
     //                          {recentStories.map((article) => {
     //                               return <WackyArticleCard article={article} isPreview={isPreview} key={article.id}/>;
     //                          })}
     //                     </div>
     //                </div>
     //           )}
     //           <div className="category-wacky-action">
     //                <>
     //                     <>
     //                          {allStories?.length > storiesPerPage && (
     //                               <>
     //                                    {recentStories >= allStories?.length ? (
     //                                         <button className="btn-show-more" onClick={handleClickShowLess}>
     //                                              Load less stories
     //                                         </button>
     //                                    ) : (
     //                                         <button className="btn-show-more" onClick={handleClickShowMore}>
     //                                              Load more stories
     //                                         </button>
     //                                    )}
     //                               </>
     //                          )}
     //                     </>
     //                </>
     //           </div>
     //      </>
     // );

     return (
          <>
               {allStories && (
                    <div className="list-best">
                         <div className="row">
                              {allStories.map((article) => {
                                   return <WackyArticleCard article={article} isPreview={isPreview} key={article.id}/>;
                              })}
                         </div>
                    </div>
               )}
          </>
     );
};
const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

WackyCardBlock.propTypes = {
     isPreview: PropTypes.bool
};

const WackyCard = connect(mapStateToProps)(WackyCardBlock);

export { WackyCard };
